import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import {Link, Outlet} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {KeyContext} from '../contexts/Contexts';
import {useContext} from 'react';
import {useLocation} from 'react-router-dom';
import {useState, useEffect} from 'react';
import {jwtDecode} from "jwt-decode";
const CLIENT_ID = "480958020813-35fvvk1mo21itllv4417h88gu5tbnpo3.apps.googleusercontent.com";

const NavbarComponent = () => {
	const {key, setKey, user, setUser, uid, setUid} = useContext(KeyContext);

	const handleCallbackResponse = async (response) => {
		console.log("Encoded JWT ID token" + response.credential);
		var userObject = jwtDecode(response.credential);
		console.log(userObject);
		console.log(userObject.email)
		setUser(userObject);
		//document.getElementById("signInDiv").hidden = true;
		const requestOptions = {
	        method: "GET",
	        headers: {
	          "Content-Type":"application/json"
	        }
	    };
	    //await fetch(`https://d4cysuxealmiekdc25ft4l3t740zhfsf.lambda-url.us-west-1.on.aws/fetch_uid/${userObject.email}`, requestOptions)
	    await fetch(`https://tyrano.daalgo.org/fetch_uid/${userObject.email}`, requestOptions)
	    //await fetch(`http://127.0.0.1:8000/fetch_uid/${userObject.email}`, requestOptions)
	    .then(
	      (response) => response.json()
	    )
	    .then((data) => {
	      console.log("inside fetching uid");
	      console.log(data);
	      setUid(data);
	    });
	}
	function handleSignOut(event){
		setUser({});
		document.getElementById("signInDiv").hidden = false;
	}
	useEffect(() => {
		/* global google */
		google.accounts.id.initialize({
		  client_id: CLIENT_ID,
		  callback: handleCallbackResponse 
		});
		console.log("helloo");
		google.accounts.id.renderButton(
		  document.getElementById("signInDiv"),
		  {theme:"light", size:"large", shape:"pills"}
		);
	},[]);
	
	
	const handleSelect = (key) => {
		setKey(key);
	};
	const location = useLocation();
	setKey(location.pathname.substring(1));
	return (
		<>
			<Navbar bg="danger" data-bs-theme="dark">
		        <Container>
		          <Nav variant="pills" activeKey = {key} onSelect={handleSelect} defaultActiveKey="link-1">
		            <Nav.Link as={Link} to = {"/"} eventKey="">
		            Home
		            </Nav.Link>
		            <Nav.Link as={Link} to = {"/exam"} eventKey="exam">
		            Exam
		            </Nav.Link>
		            <Nav.Link as={Link} to = {"/table"} eventKey="table">
		            Table
		            </Nav.Link>
		          </Nav>
		          <div id = "signInDiv" style = {{float:"right"}}></div>
		        </Container>
		        
	      	</Navbar>
  			<Outlet/>
		</>
	);
};
export default NavbarComponent;