import readXlsxFile from 'read-excel-file';
import writeXlsxFile from 'write-excel-file';
import React, {useState} from 'react';
import {useEffect} from 'react';
import {useContext} from 'react';
import {FileContext} from '../contexts/Contexts';
import {Button} from '@mui/material';
import {FileDownload} from '@mui/icons-material';
import {ThemeProvider, createTheme} from '@mui/material/styles';

const theme = createTheme({
  palette:{
    primary: {
      main:'#1e90ff',
    },
    secondary:{
      main:'#ff0000',
    }, 
  },
});
function TableComponent() {
  //const [table, setTable] = useState(null);
  const {rFile, setRFile, table, setTable, title, setTitle, slots_cors, setSlots_cors, corsindex, setCorsindex, corstds, setCorstds, stdsindex, setStdsindex, uid, setUid, corHours, setCorHours, nights, setNights, corooms, setCorooms, corteas, setCorteas, splits, unavailable, corteaslist, classeslist} = useContext(FileContext);
  useEffect(() => {
    //setTable(document.getElementById('excel-table'));
    document.getElementById('main-div').appendChild(table);
  },[]);
  useEffect(() => {
    if(!table.hasChildNodes()){
      fileChangeHandler();
    }
  },[rFile]);
  const input = document.getElementById('file-input');
  //const table = document.getElementById('excel-table');
  /**
   * @param numOfSteps: Total number steps to get color, means total colors
   * @param step: The step number, means the order of the color
   */
  function rainbow(numOfSteps, step) {
      // This function generates vibrant, "evenly spaced" colours (i.e. no clustering). This is ideal for creating easily distinguishable vibrant markers in Google Maps and other apps.
      // Adam Cole, 2011-Sept-14
      // HSV to RBG adapted from: http://mjijackson.com/2008/02/rgb-to-hsl-and-rgb-to-hsv-color-model-conversion-algorithms-in-javascript
      var r, g, b;
      var h = step / numOfSteps;
      var i = ~~(h * 6);
      var f = h * 6 - i;
      var q = 1 - f;
      switch(i % 6){
          case 0: r = 1; g = f; b = 0; break;
          case 1: r = q; g = 1; b = 0; break;
          case 2: r = 0; g = 1; b = f; break;
          case 3: r = 0; g = q; b = 1; break;
          case 4: r = f; g = 0; b = 1; break;
          case 5: r = 1; g = 0; b = q; break;
      }
      var c = "#" + ("00" + (~ ~(r * 255)).toString(16)).slice(-2) + ("00" + (~ ~(g * 255)).toString(16)).slice(-2) + ("00" + (~ ~(b * 255)).toString(16)).slice(-2);
      return (c);
  }
  
  const intersection = (a, b) => {
    if(b?.size < a?.size){
      var tmp = a;
      a = b;
      b = tmp;
    }
    var res = new Set();
    a?.forEach((e) => {
      if(b?.has(e)){
        res.add(e);
      }
    })
    return res;
  }

  const union = (org, targ) => {
    targ.forEach((e) => {
      org.add(e);
    })
  }

  //find the place of '(' matched with the last ')' to find the the exact name of the course
  const leftMatch = (cname) => {
    let leftidx = cname.length-1;
    let cnt = 0;
    for(;leftidx >= 0;leftidx--){
      if(cname[leftidx] === ')'){
        cnt += 1;
      }
      else if(cname[leftidx] === '('){
        cnt -= 1;
      }
      if(cnt === 0)
        break;
    }
    return leftidx;
  }

  const availableCheck = (day, ts, cname, corteas, unavailable) => {
    console.log("cname", cname);
    for(let tea of corteas.get(cname)){
      let unavailableTimes = unavailable.get(tea);
      if(unavailableTimes?.has(day)){
        return false;
      }
      if(unavailableTimes?.has(ts)){
        return false;
      }
    }
    return true;
  }

  //write the modified result to the excel file
  async function xlsxFileSaveHandler(){
    console.log("saving xlsx file");
    console.log(slots_cors);
    
    const m = slots_cors.length;
    const n = slots_cors[0].length;
    //saving the 1st sheet which is raw data
    const data0 = [];
    const columns0 = [];
    const headrow0 = [];
    columns0.push({width:12});
    headrow0.push({});
    for(let j = 1; j < corsindex.size+1; j++){
      columns0.push({width:2*corsindex.get(j).length});
      headrow0.push({type:String, value:corsindex.get(j), wrap:true, fontSize:10, fontWeight:"bold", height:40, align:"center", alignVertical:"center", backgroundColor:"#FF0000"});
    }
    data0.push(headrow0);
    console.log("checking in progress");
    console.log(stdsindex);
    console.log(corsindex);
    console.log(corstds);
    for(let i = 1; i < stdsindex.size+1; i++){
      let row = [];
      row.push({type:String, value:stdsindex.get(i), wrap:true, fontSize:10, fontWeight:"bold", height:40, align:"center", alignVertical:"center", backgroundColor:"#00FF00"});
      for(let j = 1; j < corsindex.size+1; j++){
        console.log("j",j);
        if(corstds.get(corsindex.get(j)).has(stdsindex.get(i))){
          row.push({type:Number, value:1, wrap:true, fontSize:10, fontWeight:"bold", height:40, align:"center", alignVertical:"center", backgroundColor:"#FF0000"});
        }
        else{
          row.push({});
        }
      }
      data0.push(row);
    }
    console.log("check log");
    console.log(corsindex);

    //saving the 2nd sheet which has course data like examHours, nights, rooms, and supervising teachers
    const data1 = [];
    const columns1 = [];
    for(let j = 0; j < corsindex.size + 1; j++){
      columns1.push({width:10});
    }
    let labels = ["examHours", "nights", "rooms", "teachers", "classes"];
    console.log("labels", labels.length);
    for(let i = 0; i < labels.length; i++){
      let row = [];
      row.push({type:String, value:labels[i], wrap:true, fontSize:10, fontWeight:"bold", height:40, align:"center", alignVertical:"center", backgroundColor:"#FF0000"});
      columns1[0].width = Math.max(columns1[0].width, labels[i].length);
      if(i==0){
        for(let j = 1; j < corsindex.size + 1; j++){
            row.push({type:Number, value:Math.floor(corHours.get(corsindex.get(j))/2), wrap:true, fontSize:10, fontWeight:"bold", height:40, align:"center", alignVertical:"center", backgroundColor:"#00FF00"});
        }
      }
      else if(i==1){
        for(let j = 1; j < corsindex.size + 1; j++){
            row.push({type:Number, value:nights.get(corsindex.get(j)), wrap:true, fontSize:10, fontWeight:"bold", height:40, align:"center", alignVertical:"center", backgroundColor:"#00FF00"});
        }
      }
      else if(i==2){
        for(let j = 1; j < corsindex.size + 1; j++){
            let roomsStr = Array.from(corooms.get(corsindex.get(j))).join("|");
            row.push({type:String, value:roomsStr, wrap:true, fontSize:10, fontWeight:"bold", height:40, align:"center", alignVertical:"center", backgroundColor:"#00FF00"});
            columns1[j].width = Math.max(columns1[j].width, roomsStr.length);
        }
      }
      else if(i==3){
        for(let j = 1; j < corsindex.size + 1; j++){
            let teasStr = Array.from(corteas.get(corsindex.get(j))).join("|");
            row.push({type:String, value:teasStr, wrap:true, fontSize:10, fontWeight:"bold", height:40, align:"center", alignVertical:"center", backgroundColor:"#00FF00"});
            columns1[j].width = Math.max(columns1[j].width, teasStr.length);
        }
      }
      else if(i==4){
        for(let j = 1; j < corsindex.size + 1; j++){
            row.push({type:String, value:classeslist[j-1], wrap:true, fontSize:10, fontWeight:"bold", height:40, align:"center", alignVertical:"center", backgroundColor:"#00FF00"});
            columns1[j].width = Math.max(columns1[j].width, classeslist[j-1].length);
        }
      }
      data1.push(row);
    }
    
    //saving the 3rd sheet which has the split(days) data of the timetable format
    console.log("splits", splits);
    const data2 = [];
    const columns2 = [];
    const headrow2 = [];
    columns2.push({width:10});
    headrow2.push({});
    for(let j = 0; j < splits.length; j++){
      columns2.push({width:10});
      headrow2.push({type:String, value: "D".concat((j+1).toString()), wrap:true, fontSize:10, fontWeight:"bold", height:40, align:"center", alignVertical:"center", backgroundColor:"#FF0000"});
    }
    data2.push(headrow2);
    let labels2 = ["morning", "afternoon", "night"]; 
    for(let i = 0; i < labels2.length; i++){
      let row = [];
      row.push({type:String, value: labels2[i], wrap:true, fontSize:10, fontWeight:"bold", height:40, align:"center", alignVertical:"center", backgroundColor:"#FF0000"});
      for(let j = 0; j < splits.length; j++){
        row.push({type:Number, value:Math.floor((splits[j][i+1]-splits[j][i])/2), wrap:true, fontSize:10, fontWeight:"bold", height:40, align:"center", alignVertical:"center", backgroundColor:"#00FF00"});
      }
      data2.push(row);
    }   

    //saving the 4th sheet which has the unavailability of teachers
    const head3 = ["teachers", "unavailable timeslots"]
    const data3 = [];
    const columns3 = [];
    const headrow3 = [];
    for(let j = 0; j < head3.length; j++){
      columns3.push({width:head3[j].length});
      headrow3.push({type:String, value: head3[j], wrap:true, fontSize:10, fontWeight:"bold", height:40, align:"center", alignVertical:"center", backgroundColor:"#FF0000"});
    }
    data3.push(headrow3);
    let uteasit = unavailable.keys(); 
    for(let i = 0; i < unavailable.size; i++){
      let row = [];
      let curtea = uteasit.next().value;
      row.push({type:String, value:curtea, wrap:true, fontSize:10, fontWeight:"bold", height:40, align:"center", alignVertical:"center", backgroundColor:"#00FF00"});
      let teasStr = Array.from(unavailable.get(curtea)).join("|");
      row.push({type:String, value:teasStr, wrap:true, fontSize:10, fontWeight:"bold", height:40, align:"center", alignVertical:"center", backgroundColor:"#00FF00"});
      data3.push(row);
    }

    //saving the final sheet which is a table
    const data = [];
    const columns = [];
    const headrow = [];
    headrow.push({type:String, value: title, span:n+1, fontSize:20, fontWeight:"bold", height:40, align:"center", alignVertical:"center", backgroundColor:"#FF0000"})
    for(let j = 1; j < n+1; j++){
      headrow.push(null);
    }
    data.push(headrow);
    const daysrow = [];
    daysrow.push(null);
    for(let j = 1; j < n+1; j++){
      daysrow.push({wrap:true, value:"DAY".concat(j.toString()), fontSize:10, fontWeight:"bold"});
    }
    data.push(daysrow);
    columns.push({width:20})
    for(let j = 1; j < n+1; j++){
      columns.push({width:0})
    }
    //data[0] is already headrow
    //data[1] is a row for days
    for(let i = 0; i < m; i++){
      data.push([])
      data[data.length-1].push({wrap:true, value:(Math.floor(i/2)+1).toString(), fontSize:10, fontWeight:"bold"})
      for(let j = 1; j < n+1; j++){
          columns[j].width = Math.max(columns[j].width, Math.max(...Array.from(slots_cors[i][j-1]).map(e => e.length)));
          data[data.length-1].push({wrap:true, fontSize:10, fontWeight:"bold", type:String, value: Array.from(slots_cors[i][j-1]).join("\n")});
      }
    }

    console.log("printing uid");
    console.log(uid);
    //uploading current table file to S3 bucket
    await writeXlsxFile([data0, data1, data2, data3, data], {columns:[columns0, columns1, columns2, columns3, columns], sheets:['students','courses','days','teachers','timetable']})
      .then(resultFile => {
        console.log("result file check");
        console.log(resultFile);
        const formData = new FormData();
        formData.append(
          "file",
          resultFile,
          'output2030.xlsx'
        );
        const requestOptions = {
          method:'POST',
          body: formData
        };
        fetch(`https://tyrano.daalgo.org/file/upload_result_file/${uid}`, requestOptions)
        //fetch(`http://127.0.0.1:8000/file/upload_result_file/${uid}`, requestOptions)
        .then(data => {
          console.log("check message");
          console.log(data);
        });
        console.log("download result xlsx file after saving to db")
        console.log("check result file before");
        console.log(resultFile)
        var a = document.createElement('a');
        var url = window.URL.createObjectURL(resultFile);
        a.href = url;
        a.download = "output2030.xlsx";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        a.parentNode.removeChild(a);
        console.log("check resultFile after");
        console.log(resultFile);
      });
  }//end of xlsxFileSaveHandler
  
  console.log("EPL check");
  //var title = "";
  const slots = [];
  //const slots_cors = [];
  //const corstds =  new Map();
  //const corsindex = new Map();
  //const stdsindex = new Map();
  const cortoslot = new Map();
  const cortimes = new Map();
  const comps = new Map();
  const corcolors = new Map();
  const stack = [];///////
  var coloridx = 0;
  const TCOLOR = 100;//the total number of colors
  const delta = 29;//relatively prime to 100, total # of colors
  ///////////////////////////////
  
  //reading data excel file
  async function fileChangeHandler(){
    //////////////////////////////////////////////////
    //need to initialize data but it is not done yet//
    //////////////////////////////////////////////////
    console.log("inside handler");
    console.log("table", table);
    //const file = e.target.files[0]
    //reading the 1st sheet: students data
    await readXlsxFile(rFile, {sheet:1}).then((rows) =>{
      rows.forEach((row,i) => {
        row.forEach((cell,j) => {
          if(i>0 || j>0){
            if(i===0){
              var cor = cell;
              corstds.set(cor,new Set());
              corsindex.set(j, cor);
            }
            else if(j===0){
              var std = cell;
              stdsindex.set(i, std);
            }
            else if(cell == 1){
              corstds.get(corsindex.get(j)).add(stdsindex.get(i));
            }
          }
        })
      });
    });
    
    //reading the 2nd sheet:
    await readXlsxFile(rFile, {sheet:2}).then((rows) =>{
      rows.forEach((row,i) => {
        row.forEach((cell,j) => { 
          console.log("cell i j",i,j,cell);
          if(j!=0){
            if(i==0){
              corHours.set(corsindex.get(j), 2*parseInt(cell));
            }
            else if(i==1){
              nights.set(corsindex.get(j), parseInt(cell));
            }
            else if(i==2){
              let examrooms = cell ? cell.split('|') : [];
              corooms.set(corsindex.get(j), new Set());
              for(const e of examrooms){
                corooms.get(corsindex.get(j)).add(e.trim());
              }
            }
            else if(i==3){
              let supervisors = cell ? cell.split('|') : [];
              corteas.set(corsindex.get(j), new Set());
              for(const teas of supervisors){
                for(const t of teas.split(',')){
                  corteas.get(corsindex.get(j)).add(t.trim());  
                }
              }
              corteaslist.push(cell);
            }
            else if(i==4){
              classeslist.push(cell);
            }
          }
        })//end of the 2nd forEach
      });
    });//completion of reading the 2nd sheet
    console.log("corHours", corHours);
    console.log("nights", nights);
    console.log("corooms", corooms);
    console.log("corteas", corteas);

    //reading the 3rd sheet
    await readXlsxFile(rFile, {sheet:3}).then((rows) =>{
      rows.forEach((row,i) => {
        row.forEach((cell,j) => { 
          console.log("cell i j",i,j,cell);
          if(j!=0){
            if(i==0){
              splits.push([0]);
            }
            else{
              splits[j-1].push(splits[j-1][splits[j-1].length-1]+2*parseInt(cell));
            }
          }
        })//end of the 2nd forEach
      });
    });//completion of reading the 3rd sheet
    console.log(splits);

    //reading the 4th sheet
    await readXlsxFile(rFile, {sheet:4}).then((rows) =>{
      rows.forEach((row,i) => {
        let tname;
        row.forEach((cell,j) => { 
          console.log("cell i j",i,j,cell);
          if(i!=0){
            if(j==0){
              tname = cell;
              unavailable.set(tname, new Set());
            }
            else{
              let cellData = cell ? cell.split('|') : [];
              for(const e of cellData){
                unavailable.get(tname).add(e);
              }
            }
          }
        })//end of the 2nd forEach
      });
    });//completion of reading the 4th sheet
    console.log("unavailable", unavailable);
    
    //reading the 5th sheet: exam timetable
    await readXlsxFile(rFile, {sheet:5}).then((rows) =>{
      rows.forEach((row,i) => {
        let tr = document.createElement('tr');
        if(i>=2){
          slots.push([]);
          slots_cors.push([]);
        }
        row.forEach((cell,j) => {
          const td = document.createElement('td');
          td.style.borderColor = "#000000";
          td.style.borderWidth = "2px";
          const tdid = i.toString().concat("|",j.toString());
          if(i>=2 && j >=1){
            td.id = tdid;
            slots[slots.length-1].push(td);
            slots_cors[slots_cors.length-1].push(new Set());
            td.draggable = false;
            td.addEventListener('dragover',(ev) => {
              ev.preventDefault();
              console.log("ondragover");
            }, false);
            td.addEventListener('drop',(ev) =>{
              ev.preventDefault();
              while(stack.length > 0){////
                let e = stack.pop();////
                e.style.borderColor = "#000000";////
                e.style.borderWidth = "2px";////
              }////
              var pcor = ev.dataTransfer.getData("prevcor");
              var hours = cortimes.get(pcor);
              var pslotidxes = cortoslot.get(pcor).split("|").map((e) => (parseInt(e)));
              pslotidxes = [pslotidxes[0]-2, pslotidxes[1]-1]
              var tslotidxes = ev.currentTarget.id.split("|").map((e) => (parseInt(e)));
              tslotidxes = [tslotidxes[0]-2,tslotidxes[1]-1]
              if(tslotidxes[0]+hours <= slots.length){
                var pcomps = comps.get(pcor);
                var q = pslotidxes[1];
                var common = new Set();
                for(let p = pslotidxes[0]; p < pslotidxes[0] + hours; p++){
                  slots_cors[p][q].delete(pcor);
                }
                q = tslotidxes[1];
                for(let p = tslotidxes[0]; p < tslotidxes[0] + hours; p++){
                  console.log(slots_cors[p][q]);
                  slots_cors[p][q].forEach((cor) => {
                    var pidx = pcor.lastIndexOf('(');
                    var cidx = cor.lastIndexOf('(');
                    union(common, intersection(corstds.get(pcor.substring(0,pidx)), corstds.get(cor.substring(0,cidx))));
                  })
                }
                console.log(common);
                if(common.size > 0){//backtrack
                  console.log("intersection",common);
                  q = pslotidxes[1];
                  for(let p = pslotidxes[0]; p < pslotidxes[0] + hours; p++){
                    slots_cors[p][q].add(pcor);
                  }
                }
                else{
                  q = pslotidxes[1];
                  for(let p = pslotidxes[0]; p < pslotidxes[0] + hours; p++){
                    slots[p][q].removeChild(pcomps[p-pslotidxes[0]]);
                  }
                  cortoslot.set(pcor, ev.currentTarget.id);
                  q = tslotidxes[1];
                  for(let p = tslotidxes[0]; p < tslotidxes[0] + hours; p++){
                    slots[p][q].appendChild(pcomps[p-tslotidxes[0]]);
                    slots_cors[p][q].add(pcor);
                  }  
                }//end of else
              }//end of if
            }, true);
          }//end of if(i>=2 && j >=1)
          
          //insert current cors to td
          if(i>=2 && j>=1){
            const curcors = cell?.split('\n')
            curcors?.forEach((cor, k)=>{
              slots_cors[i-2][j-1].add(cor);
              const tdiv = document.createElement('div');
              if(corcolors.has(cor)){
                tdiv.style.backgroundColor = corcolors.get(cor);
              }
              else{
                corcolors.set(cor, rainbow(TCOLOR, coloridx));
                coloridx = (coloridx + delta)%TCOLOR;
                tdiv.style.backgroundColor = corcolors.get(cor);
              }
              tdiv.textContent = cor;
              if(!comps.has(cor)){
                comps.set(cor,[]);
              }
              comps.get(cor).push(tdiv);
              if(!cortoslot.has(cor)){
                cortoslot.set(cor, tdid);
                cortimes.set(cor, 1);
              }
              else{
                cortimes.set(cor, cortimes.get(cor) + 1);  
              }
              tdiv.draggable = true;
              tdiv.style.border = "thin solid #FFFFFF";  
              tdiv.id = i.toString().concat("|",j.toString(),"|",k.toString());
              tdiv.addEventListener('dragstart', (ev) =>{
                ev.dataTransfer.setData("prevcellid", ev.target.id);
                ev.dataTransfer.setData("prevcor", ev.target.textContent);
                
                let curcor = ev.target.textContent;
                let h = cortimes.get(curcor);
                let slotpos = cortoslot.get(curcor).split("|").map((e) => (parseInt(e)));
                slotpos = [slotpos[0]-2, slotpos[1]-1];
                for(let i = slotpos[0]; i < slotpos[0]+h; i++){
                  slots_cors[i][slotpos[1]].delete(curcor);
                }
                let curcname = curcor.substring(0,leftMatch(curcor));
                for(let i = 0; i <= slots.length - h; i++){
                  for(let j = 0; j < slots[0].length; j++){
                    let common = new Set();
                    let tcommon = new Set();
                    let rcommon = new Set();
                    let isTeasAvailable = true;
                    let dayStr = "D".concat(i.toString());
                    for(let k = i; k < i+h; k++){
                      slots_cors[k][j].forEach((cor) => {
                        let cname = cor.substring(0,leftMatch(cor));
                        let tsStr = dayStr.concat("T", k.toString());
                        isTeasAvailable = isTeasAvailable && availableCheck(dayStr, tsStr, curcname, corteas, unavailable);
                        union(common, intersection(corstds.get(curcname), corstds.get(cname)));
                        union(tcommon, intersection(corteas.get(curcname), corteas.get(cname)));
                        union(rcommon, intersection(corooms.get(curcname), corooms.get(cname)));
                        if(common.size > 0 || tcommon.size > 0 || rcommon.size > 0 || !isTeasAvailable){
                          return false;//break out of forEach
                        }
                        
                      })
                      if(common.size>0 || tcommon.size > 0 || rcommon.size > 0 || !isTeasAvailable){
                        break;
                      }
                    }
                    console.log("common", "tcommon", "rcommon", "ucommon", common, tcommon, rcommon, isTeasAvailable)
                    if(common.size == 0 && tcommon.size == 0 && rcommon.size == 0 && isTeasAvailable){
                      stack.push(slots[i][j]);
                    }
                  }
                }
                console.log("stack", stack);
                stack.forEach((td) => {
                  td.style.borderColor = "#FF7878";//golen color to denote available time slots
                  td.style.borderWidth = "5px";
                });
                console.log("stack", stack);
                for(let i = slotpos[0]; i < slotpos[0] + h; i++){
                  slots_cors[i][slotpos[1]].add(curcor);
                }
              }, false);
              td.appendChild(tdiv);   
            });
          }
          else{
            const tdiv = document.createElement('div');
            if(i == 0 && j == 0){
              setTitle(cell);
              //title = cell;
            }
            tdiv.textContent = cell;
            td.appendChild(tdiv);
          }
          if(i>0){
            tr.appendChild(td);  
          }
        })
        table.appendChild(tr);
      })
    })//end of readxlsxfile of the second sheet
  }
  return (
    <div className="App-column" id = "main-div">
      <ThemeProvider theme = {theme}>
      <Button onClick = {xlsxFileSaveHandler} variant = "contained" style = {{marginTop:10, marginBottom:10}} color = "primary" startIcon={<FileDownload />}>
      Save
      </Button>
      </ThemeProvider>
      { 
        table != null &&
        <div className = "App-table-head1" style ={{marginTop:10, marginBottom:10}}>{title}</div>
      }
    </div>
  );
}

export default TableComponent;
