import logo from '../logo5.svg';
import '../App.css';
import {useNavigate} from 'react-router-dom';
import {useContext, useState} from 'react';
import {FileContext} from '../contexts/Contexts';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import {LoadingButton} from '@mui/lab';

const theme = createTheme({
	palette:{
		primary: {
			main:'#ff00ff',
		},
		secondary:{
			main:'#ff0000',
		}, 
	},
});

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});


const Home = () => {
	//rFile: result(table) file
	const {rFile, setRFile, user, setUser, table, setTable, slots_cors, setSlots_cors, corsindex, setCorsindex, corstds, setCorstds, stdsindex, setStdsindex} = useContext(FileContext);
	//payment function
  function onClickPayment() {
    /* 1. 가맹점 식별하기 */
    const {IMP} = window;
    IMP.init('imp71024237');

    /* 2. 결제 데이터 정의하기 */
    const data = {
      pg: 'tosspayments',                           // PG사
      pay_method: 'card',                           // 결제수단
      merchant_uid: `mid_${new Date().getTime()}`,   // 주문번호
      amount: 100,                                 // 결제금액
      name: 'exam time table',                  // 주문명
      buyer_name: '홍길동',                           // 구매자 이름
      buyer_tel: '01012341234',                     // 구매자 전화번호
      buyer_email: 'example@example',               // 구매자 이메일
      buyer_addr: '신사동 661-16',                    // 구매자 주소
      buyer_postcode: '06018',                      // 구매자 우편번호
    };

    /* 4. 결제 창 호출하기 */
    IMP.request_pay(data, callback);
  }

  /* 3. 콜백 함수 정의하기 */
  function callback(response) {
    const {
      merchant_uid,
      error_msg,
    } = response;
    console.log("결재완료", response);
    navigate('/table');
	  //download the result file
	  console.log("check data before");
	  console.log(rFile)
	  var a = document.createElement('a');
	  var url = window.URL.createObjectURL(rFile);
	  a.href = url;
	  a.download = "result2024.xlsx";
	  document.body.appendChild(a);
	  a.click();
	  window.URL.revokeObjectURL(url);
	  a.parentNode.removeChild(a);
	  console.log("check data after");
	  console.log(rFile);
  }//end of callback

	let navigate = useNavigate();
	//result(table) file change handler
	async function rFileChangeHandler(e){
		setTable(document.createElement('table'));
		setSlots_cors([]);
		setRFile(e.target.files[0]);
		navigate('/table');
	}
	const [loading, setLoading] = useState(false);
  const [paying, setPaying] = useState(false);
	//input(raw data) file change handler
	const [selectedFile, setSelectedFile] = useState(null);
	const fileChangeHandler = (e) => {
		setTable(document.createElement('table'));
		setSlots_cors([]);
		setSelectedFile(e.target.files[0]);
		console.log(e.target.files[0]);
	}
	const handleSubmit = async (e) => {
		setLoading(true);
		e.preventDefault();
		console.log(user);
		const formData = new FormData();
		formData.append(
		  "file",
		  selectedFile,
		  selectedFile.name
		);
		const requestOptions = {
		  method:'POST',
		  body: formData
		};
		//AWS lambda api
		//await fetch("https://d4cysuxealmiekdc25ft4l3t740zhfsf.lambda-url.us-west-1.on.aws/file/upload_download/", requestOptions)
		await fetch("https://tyrano.daalgo.org/file/upload_download/", requestOptions)
		//await fetch("http://127.0.0.1:8000/file/upload_download/", requestOptions)
		.then(res => res.blob())
		.then(data => {
		  //blob and excel file can be processed with same method
		  console.log("api called and timetable constructed");
		  console.log(data);
		  setLoading(false);
		  setPaying(true);
		  setTable(document.createElement('table'));
		  setRFile(data);
		  setSlots_cors([]);
		  setCorsindex(new Map());
		  setCorstds(new Map());
		  setStdsindex(new Map());
		  

		});//end of await
	}
	return (

	<div style = {{display:"flex", flexDirection:"column", justifyContent:"center", height:"100vh", width:"100%", textAlign:"center", alignItems:"stretch"}}>
	  <div style = {{flex:2, display:"flex", flexDirection:"column", justifyContent:"center", height:"auto", backgroundColor:"tomato", color:"white", fontFamily:"Times New Roman"}}>
	    <img src={logo} className="App-img" alt="logo" />
	  </div >
	  <div  style = {{flex:1, height:"auto", backgroundColor:"dodgerblue", color:"black", textAlign:"center", marginTop:0, marginBottom:0, paddinTop:0, paddingBottom:0}} >
	    <ThemeProvider theme = {theme}>
	    <div style = {{display:"flex", height:"100%", flexDirection:"column", justifyContent:"space-evenly", alignItems:"center"}}>
		    <Button
		    	component = "label"
		      variant="contained"
		      size = "small"
		      startIcon={<CloudUploadIcon />}
		      color = "primary"
		    >
		      Upload file
		      <VisuallyHiddenInput onChange = {fileChangeHandler} name = "xlsxfile" type = "file" accept = ".xlsx" />
		    </Button>
		    
		    {
		        Object.keys(user).length != 0 && <>
		        <LoadingButton
		          size="small"
		          onClick={handleSubmit}
		          endIcon={<CloudDownloadIcon />}
		          loading={loading}
		          loadingPosition="end"
		          variant="contained"
		          color = "primary"
		        >
		          Generate Timetable
		        </LoadingButton>
        		</>
      	}
      	{
	      	paying == true && <Button variant="contained" size="small" onClick = {onClickPayment} startIcon={<CreditCardIcon />}>
	          			Pay
	    		</Button>
    		}
	    </div>
	    </ThemeProvider>
	  </div>
	  <div style = {{flex:1, height:"auto", textAlign:"center", backgroundColor:"mediumseagreen", paddinTop:10, paddingBottom:10}} >
	  	<div className="App-footer">
	  		<h1 style = {{flex:1, marginTop:110, marginBottom:0, paddinTop:10, paddingBottom:10, fontFamily:"Great Vibes cursive", fontWeight:"bold", textAlign:"center", width:"100%", alignItems:"stretch"}}> TheAlgo </h1>
	  	</div>
	  </div>
	</div>
	);
};
export default Home;